import React, {useEffect, useState} from "react";
import {translate, ValidatedField, ValidatedForm} from "react-jhipster";
import {useAppDispatch, useAppSelector} from "../config/localStorage";
import {savePassword} from "../reducers/password.reducer";
import ButtonPrimary from "../../shared/components/Button/ButtonPrimary";
import PasswordStrengthBar from "../../shared/components/password/password-strength-bar";
import {getUser} from "../reducers/authentication";
import Label from "../../shared/components/Label/Label";
import ESimSwitch from "../../shared/components/MySwitch/ESimSwitch";
import AccountBillingInformation from "./AccountBillingInformation";
import toast from "react-hot-toast";
import {reset} from "../reducers/password.reducer";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";

const AccountPassword = () => {
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        setError,
        reset: resetForm,
        formState: {errors},
    } = useForm({mode: 'onTouched', defaultValues: {}});


    const handleValidSubmit = (values: any) => {
        //@ts-ignore
        dispatch(savePassword({currentPassword: values.currentPassword, newPassword: values.newPassword}));
        console.log('values', values);
    };

    const updatePassword = (event: any) => setPassword(event.target.value);

    const account = useAppSelector(state => state.authentication.account);
    const successMessage = useAppSelector(state => state.password.updateSuccess);
    const errorMessage = useAppSelector(state => state.password.updateFailure);

    useEffect(() => {
        dispatch(getUser());

        console.log('successMessage', successMessage);
        if (successMessage) {
            dispatch(reset());

            navigate('/account');
        } else if (errorMessage) {
            console.log('errorMessage', errorMessage);
            // @ts-ignore
            setError('currentPassword', {type: 'manual', message: 'global.messages.error.passwordmatch'});
        }


        dispatch(reset());
    }, [successMessage, errorMessage]);


    // @ts-ignore
    return (
        <div className={`nc-AccountPage `}>
            <div className="grid md:grid-cols-1 gap-5 lg:grid-cols-1 xl:gap-8 mt-12 mb-12">
                <div
                    className="p-6 bg-neutral-50 dark:bg-neutral-800  border border-slate-200 dark:border-slate-700 rounded-lg">
                    <h2
                        className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">{translate("password-update.title")}</h2>

                    <div className="grid gap-5 xl:gap-8">
                        {/* HEADING */}
                        <ValidatedForm id="password-form"
                            //@ts-ignore

                                       onSubmit={handleSubmit(handleValidSubmit)}>

                            <div className="relative px-3 py-2">
                                <Label
                                    className={'text-neutral-800 dark:text-neutral-200'}>{translate("password-update.current-password-label")}</Label>
                                <ValidatedField
                                    labelClass={'text-neutral-800 dark:text-neutral-200 mt-6'}
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.currentPassword}
                                    placeholder={translate("password-update.current-password-placeholder")}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    name="currentPassword"
                                    type="password"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate('account.password-reset.validate.current-password.required')
                                        },
                                    }}
                                    data-cy="currentPassword"
                                />

                            </div>
                            <div className="relative px-3 py-2">
                                <Label
                                    className={'text-neutral-800 dark:text-neutral-200'}>{translate("password-update.current-password-label")}</Label>
                                <ValidatedField
                                    labelClass={'text-neutral-800 dark:text-neutral-200 mt-12'}
                                    placeholder={translate("password-update.new-password-placeholder")}
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.newPassword}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    name="newPassword"
                                    type="password"
                                    validate={{
                                        required: {
                                            value: true,
                                            message: translate('account.password-reset.validate.new-password.required')
                                        },
                                        minLength: {
                                            value: 6,
                                            message: translate('account.password-reset.validate.new-password.minlength')
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: translate('account.password-reset.validate.new-password.maxlength')
                                        },
                                    }}
                                    onChange={updatePassword}
                                    data-cy="newPassword"
                                />
                                <p className="text-neutral-500 dark:text-neutral-400  text-xs  w-full">
                                    <PasswordStrengthBar password={password}/>

                                </p>

                            </div>


                            <div className="relative px-3 py-2">
                                <Label
                                    className={'text-neutral-800 dark:text-neutral-200'}>{translate("password-update.confirm-new-password-label")}</Label>
                                <ValidatedField
                                    labelClass={'text-neutral-800 dark:text-neutral-200 mt-12'}
                                    placeholder={translate("password-update.confirm-password-placeholder")}
                                    register={register}
                                    //@ts-ignore
                                    error={errors?.confirmPassword}
                                    inputClass={'block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1 placeholder-gray-300'}
                                    name="confirmPassword"
                                    type="password"
                                    validate={{
                                        required: {value: true, message: translate("global.error.password-confirm")},
                                        minLength: {
                                            value: 4,
                                            message: translate("global.error.password-length-min")
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: translate("global.error.password-length-max")
                                        },
                                        validate: v => v === password || translate("global.error.password-mismatch"),
                                    }}
                                    data-cy="confirmPassword"
                                />

                            </div>
                            <ButtonPrimary
                                className={'buyCardButton bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-white'}
                                type="submit" data-cy="submit">
                                {translate("password-update.submit-button")}
                            </ButtonPrimary>
                        </ValidatedForm>
                    </div>
                </div>


            </div>


        </div>
    );
};

export default AccountPassword;
