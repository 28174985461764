import React, {Fragment, useRef, useState} from "react";
import Select from "react-select/async";
import {components} from "react-select";
import {IPlan} from "./model/plan.model";
import {useNavigate} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import SimView from "../shared/components/SimView";
import BgGlassmorphism from "../shared/esim/BgGlassmorphism";
import {ICountry} from "./model/country";
import {useAppDispatch, useAppSelector} from "./config/localStorage";
import {findTemplates} from "./reducers/template";
import {Dialog, Popover, Transition} from "../headlessui";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import ButtonPrimary from "../shared/components/Button/ButtonPrimary";
import ButtonThird from "../shared/components/Button/ButtonThird";
import Radio from "../shared/components/Radio/Radio";
import ButtonClose from "../shared/components/Button/ButtonClose";
import {IOperator} from "./model/operator.model";
import {translate} from "react-jhipster";
import Nav from "../shared/components/Nav/Nav";
import NavItem from "../shared/components/Nav/NavItem";
import {useAppState} from "./config/AppStateContext";
import {updatePopularCountries} from "./reducers/popular-countries.reducer";
import ModalQuickView from "../shared/components/ModalView";
import {planUrlName} from "../shared/components/PlanQuickView";

const DATA_VOLUME_LOW_HIGH = "DATA_VOLUME_LOW_HIGH";
const DATA_VOLUME_HIGH_LOW = "DATA_VOLUME_HIGH_LOW";
const PRICE_LOW_HIGH = "PRICE_LOW_HIGH";
const PRICE_HIGH_LOW = "PRICE_HIGH_LOW";
const ALPHABETICALLY = "ALPHABETICALLY";

const flagsByIso: Record<string, HTMLImageElement> = {};

const image = new Image()
image.src = require(`../shared/img/country/ro`)

const countryConstList = ['ad','ae','af','ag','ai','al','am','an','ao','aq','ar','as','at','au','aw','ax','az','ba','bb','bd','be','bf','bg','bh','bi','bj','bl','bm','bn','bo','bq','br','bs','bt','bv','bw','by','bz','ca','cc','cd','cf','cg','ch','ci','ck','cl','cm','cn','co','cr','cu','cv','cw','cx','cy','cz','de','dj','dk','dm','do','dz','ec','ee','eg','eh','er','es','et','fi','fj','fk','fm','fo','fr','ga','gb','gd','ge','gf','gg','gh','gi','gl','gm','gn','gp','gq','gr','gs','gt','gu','gw','gy','hk','hm','hn','hr','ht','hu','id','ie','il','im','in','io','iq','ir','is','it','je','jm','jo','jp','ke','kg','kh','ki','km','kn','kp','kr','kw','ky','kz','la','lb','lc','li','lk','lr','ls','lt','lu','lv','ly','ma','mc','md','me','mf','mg','mh','mk','ml','mm','mn','mo','mp','mq','mr','ms','mt','mu','mv','mw','mx','my','mz','na','nc','ne','nf','ng','ni','nl','no','np','nr','nu','nz','om','pa','pe','pf','pg','ph','pk','pl','pm','pn','pr','ps','pt','pw','py','qa','re','ro','rs','ru','rw','sa','sb','sc','sd','se','sg','sh','si','sj','sk','sl','sm','sn','so','sr','ss','st','sv','sx','sy','sz','tc','td','tf','tg','th','tj','tk','tl','tm','tn','to','tr','tt','tv','tw','tz','ua','ug','us','uy','uz','va','vc','ve','vg','vi','vn','vu','wf','ws','xk','ye','yt','za','zm','zw'];

countryConstList.forEach((country) => {
    const image = new Image();
    image.src = require(`../shared/img/country/${country}`);
    image.alt = country;
    //@ts-ignore
    image.rel = 'preload';
    image.className = 'async-option-image mr-1';
    flagsByIso[country] = image;
});


const SORT_ORDER = [
    {name: "Price Low - High", id: PRICE_LOW_HIGH, translate: 'home.sort.price-low-high'},
    {name: "Price High - Low", id: PRICE_HIGH_LOW, translate: 'home.sort.price-high-low'},
    {name: "Data Low - High", id: DATA_VOLUME_LOW_HIGH, translate: 'home.sort.data-low-high'},
    {name: "Data High - Low", id: DATA_VOLUME_HIGH_LOW, translate: 'home.sort.data-high-low'},
    {name: "Alphabetically", id: ALPHABETICALLY, translate: 'home.sort.alphabetically'},

];

export const extractUniqOperator = (operators: IOperator[]) => {
    return operators.map(operator => operator.countryIso2).filter((x, i, a) => a.indexOf(x) === i);
}

export const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" style={{color: props.secondaryColor}}
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M22 22L20 20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </svg>
        </components.DropdownIndicator>
    );
};

const Home = () => {
    const {state} = useAppState();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState<IPlan[]>([]);
    let andClause = useRef<boolean>(true);
    const [sortOrderStates, setSortOrderStates] = useState<string>(state.tenant.productManagement.defaultProductSort);
    const countries: ICountry[] = useAppSelector(state => state.country.countries);
    const countriesLoading = useAppSelector(state => state.country.loading);
    const templatesLoading = useAppSelector(state => state.template.loading);
    const templates: IPlan[] = useAppSelector(state => state.template.templates);
    const [defaultCountries, setDefaultCountries] = useState<any[]>([]);
    const [tabActive, setTabActive] = useState<string>(state.tenant.productManagement.defaultLabel);
    const [tabMenu, setTabMenu] = useState<any>([]);
    const secondaryColor = state.tenant.secondaryColor;
    var currentLocale = useAppSelector(state => state.locale.currentLocale);
    const {id} = useParams();
    const [showModal, setShowModal] = useState<boolean>(false);
    //@ts-ignore
    const [modalPlan, setModalPlan] = useState<IPlan>(undefined);
    const [modalClosed, setModalClosed] = useState<boolean>(false);

    React.useEffect(() => {
        let countries: any[];
        if (templates.length === 0 && !templatesLoading) {
            dispatch(findTemplates());
        }

        const searchParams = new URLSearchParams(location.search);
        const countryParam = searchParams.get('country');
        countries = countryParam ? countryParam.split(',') : [];

        setDefaultCountries(location.search && countries ? countries : []);

        if (!templatesLoading) {
            defaultTemplateValues(defaultCountries);
        }
        const tabs: string[] = [];
        templates.forEach(item => {
            if (item.label != null && !item.hideDestinations) {
                tabs.push(item.label)
            }
        })
        // @ts-ignore
        const distinctTab = [...new Set(tabs)];
        const menus: any[] = state.tenant.productManagement.navMenu.filter((item: any) => {
            return distinctTab.indexOf(item.label) !== -1
        });
        if (state.tenant.productManagement.enable && distinctTab.length > menus.length) {
            menus.push({
                "label": "UNKNOWN",
                "title": "custom.unknown",
                "priority": 9999,
                "icon": '<svg width="24"  height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0001 7.88989L10.9301 9.74989C10.6901 10.1599 10.8901 10.4999 11.3601 10.4999H12.6301C13.1101 10.4999 13.3001 10.8399 13.0601 11.2499L12.0001 13.1099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.30011 18.0399V16.8799C6.00011 15.4899 4.11011 12.7799 4.11011 9.89993C4.11011 4.94993 8.66011 1.06993 13.8001 2.18993C16.0601 2.68993 18.0401 4.18993 19.0701 6.25993C21.1601 10.4599 18.9601 14.9199 15.7301 16.8699V18.0299C15.7301 18.3199 15.8401 18.9899 14.7701 18.9899H9.26011C8.16011 18.9999 8.30011 18.5699 8.30011 18.0399Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>'
            })
        }

        setTabMenu(menus);

        updateCountries(countries);
    }, [templates, templatesLoading]);

    React.useEffect(() => {
        if (!templatesLoading && location.pathname.includes("plan")) {
            const selectedTemplate = templates.find(p => p.id === id || id === planUrlName(p.name));
            if (selectedTemplate) {
                setShowModal(true);
                setModalPlan(selectedTemplate);
                if (selectedTemplate.label)
                    setTabActive(selectedTemplate.label)
            }
            return;
        }

        if (modalClosed) {
            setModalClosed(false);
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        const countries = searchParams.get('country')?.split(',') || [];

        updateCountries(countries);
        filterResults(countries);

    }, [tabActive, location, id, templatesLoading]);

    const promiseOptions = (inputValue: string) =>
        new Promise<any[]>((resolve) => {
            resolve(filterCountries(inputValue));
        });

    const filterCountries = (inputValue: string) => {
        return countries
            .filter((i) => {
                let isAlternativeName = false;
                if (i.i18nAlternativeName && i.i18nAlternativeName[currentLocale]) {
                    isAlternativeName = i.i18nAlternativeName[currentLocale].toLowerCase().includes(inputValue.toLowerCase())
                }
                if (translate(`global.countries.${i.countryIso2}`)) {
                    return translate(`global.countries.${i.countryIso2}`).toLowerCase().includes(inputValue.toLowerCase()) || isAlternativeName
                }
                return false;
            })
            .map(item => (
                {
                    value: item.countryIso2, label: <div className={'flex items-center space-x-4'}>
                      <span dangerouslySetInnerHTML= {{__html:   flagsByIso[item.countryIso2].outerHTML}} />


                        {translate(`global.countries.${item.countryIso2}`)}
                    </div>
                }
            ));
    };

    const change = (countrySelectValues: any, action: any) => {
        console.log("change", countrySelectValues, action);
        if (action.action === 'select-option') {
            updatePopularity(action.option.value);
        }
        let countries: string[] = countrySelectValues.map((country: any) => country.value);
        navigate("?country=" + countries.join(','));
        if (countries.length === 0) {
            navigate("/");
        }
        filterResults(countries);
    }

    const updatePopularity = (country: string) => {
        dispatch(updatePopularCountries(country));
    }

    const filterResults = (countries: string[]) => {
        let result = getFilteredData(countries);
        if (state.tenant.productManagement && state.tenant.productManagement.enable) {
            result = result.filter((item) => item.label === tabActive);
        }
        sort(result);

    }


    const sort = (result: any[]) => {
        switch (sortOrderStates) {
            case DATA_VOLUME_LOW_HIGH : {
                result = result.sort((one, two) => (one.data - two.data) || planNameCompare(one, two));
                break;
            }
            case DATA_VOLUME_HIGH_LOW : {
                result = result.sort((one, two) => (two.data - one.data) || planNameCompare(one, two));
                break;
            }
            case PRICE_LOW_HIGH : {
                result = result.sort((one, two) => (one.price - two.price) || planNameCompare(one, two));
                break;
            }
            case PRICE_HIGH_LOW : {
                result = result.sort((one, two) => (two.price - one.price) || planNameCompare(one, two));
                break;
            }
            case ALPHABETICALLY : {
                result = result.sort((a, b) => planNameCompare(a, b) || b.price - a.price);
                break;
            }
        }
        result = result.filter((item) => item.hideDestinations === false);
        setFilterData([])
        setTimeout(() => {
            setFilterData(result)
        }, 100);

    }

    const planNameCompare = (a: IPlan, b: IPlan) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
        })
    }

    const switchChange = () => {
        let countries: string[] = [];
        andClause.current = !andClause.current;
        if (location.search) {
            const searchParams = new URLSearchParams(location.search);
            const countryParam = searchParams.get('country');
            countries = countryParam ? countryParam.split(',') : [];
        }
        filterResults(countries);
    }

    const defaultTemplateValues = (country: string[]) => {
        let countries: string[] = [];
        if (location.search.split('?country=')[1]) {
            const searchParams = new URLSearchParams(location.search);
            const countryParam = searchParams.get('country');
            countries = countryParam ? countryParam.split(',') : [];
        }
        filterResults(countries);
        return country;
    }

    const updateCountries = (defaultCountries: any[]) => {
        let returnValues: any[] = [];
        countries.forEach(item => {
            if (defaultCountries.indexOf(item.countryIso2) !== -1) {
                returnValues.push({
                    value: item.countryIso2,
                    label: <div className={'flex items-center space-x-4'}><img
                        alt={item.countryIso2}
                        src={require(`../shared/img/country/${item.countryIso2.toLowerCase()}`)}
                        className={'async-option-image mr-1'}/>{translate(`global.countries.${item.countryIso2}`)}</div>
                })
            }
        })
        setDefaultCountries(returnValues);
    }

    const isSwitchDisabled = () => {
        const searchParams = new URLSearchParams(location.search);
        const countryParam = searchParams.get('country');
        const countries = countryParam ? countryParam.split(',') : undefined;
        return countries === undefined;
    }

    const renderXClear = () => {
        return (
            <span
                className="flex-shrink-0 w-4 h-4 rounded-full  text-white flex items-center justify-center ml-3 cursor-pointer"
                style={{backgroundColor: secondaryColor}}
            ><svg
                xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor"><path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path></svg></span>
        );
    };

    const renderTabIsOnSale = () => {
        return (

            <div
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none ${
                    !andClause.current
                        ? "border-primary-500 bg-primary-50 text-primary-900"
                        : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                } ${isSwitchDisabled() ? 'disabled' : ''}`}
                onClick={() => switchChange()}
            >
                <svg
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{color: secondaryColor}}
                >
                    <path
                        d="M3.9889 14.6604L2.46891 13.1404C1.84891 12.5204 1.84891 11.5004 2.46891 10.8804L3.9889 9.36039C4.2489 9.10039 4.4589 8.59038 4.4589 8.23038V6.08036C4.4589 5.20036 5.1789 4.48038 6.0589 4.48038H8.2089C8.5689 4.48038 9.0789 4.27041 9.3389 4.01041L10.8589 2.49039C11.4789 1.87039 12.4989 1.87039 13.1189 2.49039L14.6389 4.01041C14.8989 4.27041 15.4089 4.48038 15.7689 4.48038H17.9189C18.7989 4.48038 19.5189 5.20036 19.5189 6.08036V8.23038C19.5189 8.59038 19.7289 9.10039 19.9889 9.36039L21.5089 10.8804C22.1289 11.5004 22.1289 12.5204 21.5089 13.1404L19.9889 14.6604C19.7289 14.9204 19.5189 15.4304 19.5189 15.7904V17.9403C19.5189 18.8203 18.7989 19.5404 17.9189 19.5404H15.7689C15.4089 19.5404 14.8989 19.7504 14.6389 20.0104L13.1189 21.5304C12.4989 22.1504 11.4789 22.1504 10.8589 21.5304L9.3389 20.0104C9.0789 19.7504 8.5689 19.5404 8.2089 19.5404H6.0589C5.1789 19.5404 4.4589 18.8203 4.4589 17.9403V15.7904C4.4589 15.4204 4.2489 14.9104 3.9889 14.6604Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9 15L15 9"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.4945 14.5H14.5035"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.49451 9.5H9.50349"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>

                <span className="line-clamp-1 ml-2">{translate('home.country-include')}</span>
                {andClause.current && renderXClear()}
            </div>
        );
    };

    const renderTabsSortOrder = () => {
        return (
            <Popover className="relative">
                {({open, close}) => (
                    <>
                        <Popover.Button
                            style={open ? {borderColor: secondaryColor} : {}}


                            className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none select-none
        
                `}
                        >
                            <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none" style={{color: secondaryColor}}>
                                <path
                                    d="M11.5166 5.70834L14.0499 8.24168"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M11.5166 14.2917V5.70834"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.48327 14.2917L5.94995 11.7583"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.48315 5.70834V14.2917"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <span className="ml-2">
                {sortOrderStates
                    ? translate(SORT_ORDER.filter(
                        (i) => i.id === sortOrderStates
                    )[0].translate)
                    : translate('home.sort.sort')}
              </span>
                            {!sortOrderStates.length ? (
                                <ChevronDownIcon className="w-4 h-4 ml-3"/>
                            ) : (
                                <span onClick={() => setSortOrderStates("")}>
                  {renderXClear()}
                </span>
                            )}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute z-40 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
                                <div
                                    className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                                        {SORT_ORDER.map((item) => (
                                            <Radio
                                                id={item.id}
                                                key={item.id}
                                                name="radioNameSort"
                                                label={translate(item.translate)}
                                                defaultChecked={sortOrderStates === item.id}
                                                onChange={setSortOrderStates}
                                            />
                                        ))}
                                    </div>
                                    <div
                                        className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                                        <ButtonThird
                                            onClick={() => {
                                                close();
                                                setSortOrderStates("");
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            {translate('home.sort.clear')}
                                        </ButtonThird>
                                        <ButtonPrimary
                                            className={'bg-primary-500 hover:bg-primary-600 text-white buyCardButton'}
                                            onClick={() => {
                                                close();
                                                sort(filterData);
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            {translate('home.sort.apply')}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        );
    };


    const [isOpenMoreFilter, setIsOpenMoreFilter] = useState(false);

    interface ILabelStatus {
        name: string;
        isDisabled: boolean;
    }

    const [labelStatuses, setLabelStatuses] = useState<ILabelStatus[]>([]);

    const openModalMoreFilter = () => setIsOpenMoreFilter(true);
    const closeModalMoreFilter = () => setIsOpenMoreFilter(false);

    const getFilteredData = (countries: string[]) => {
        let result: any[] = [];
        templates.forEach(item => {
            if (andClause.current) {
                // @ts-ignore
                countries.every(r => extractUniqOperator(item.operators).includes(r)) && result.push(item);
            } else {
                // @ts-ignore
                extractUniqOperator(item.operators).some(r => countries.includes(r)) && result.push(item);
                if (andClause.current === false && countries.length === 0) {
                    result.push(item);
                }
            }
        })
        return result;
    }

    const isLabelDisabled = (label: string): boolean => {
        let countries: string[] = [];
        if (location.search) {
            const searchParams = new URLSearchParams(location.search);
            const countryParam = searchParams.get('country');
            countries = countryParam ? countryParam.split(',') : [];
        }
        let result = getFilteredData(countries);
        if (state.tenant.productManagement && state.tenant.productManagement.enable) {
            result = result.filter((item) => item.label === label);
        }
        if (result.length === 0 && label === tabActive && state.tenant.productManagement.defaultLabel) {
            const activeLables = labelStatuses.filter((item) => !item.isDisabled);
            if (activeLables.length !== 0) {
                setTabActive(activeLables[0].name);
            }
        }

        result = result.filter((item) => item.hideDestinations === false);

        const element = labelStatuses.filter((item) => item.name === label);
        if (element.length !== 0) {
            return element[0].isDisabled = result.length === 0;
        } else {
            labelStatuses.push({name: label, isDisabled: result.length === 0});
        }
        // setLabelStatuses(labelStatuses);

        return result.length === 0;
    }

    const renderTabMobileFilter = () => {
        return (
            <div className="flex-shrink-0">
                <div
                    className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none`}
                    onClick={openModalMoreFilter}
                >
                    <svg
                        className="w-4 h-4"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{color: secondaryColor}}
                    >
                        <path
                            d="M22 6.5H16"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6 6.5H2"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M22 17.5H18"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M8 17.5H2"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <span className="ml-2">{translate('home.sort.sort')}</span>
                    {renderXClear()}
                </div>

                <Transition appear show={isOpenMoreFilter} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed inset-0 z-50 overflow-y-auto"
                        onClose={closeModalMoreFilter}
                    >
                        <div className="min-h-screen text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                &#8203;
              </span>
                            <Transition.Child
                                className="inline-block h-screen w-full max-w-4xl"
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                                    <div
                                        className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            {translate('home.sort.filter')}

                                        </Dialog.Title>
                                        <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter}/>
                    </span>
                                    </div>

                                    <div className="flex-grow overflow-y-auto">
                                        <div
                                            className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                                            <div className="py-7">
                                                <h3 className="text-xl font-medium">{translate('home.sort.sort')}</h3>
                                                <div className="mt-6 relative ">
                                                    <div className="relative flex flex-col space-y-5">
                                                        {SORT_ORDER.map((item) => (
                                                            <Radio
                                                                id={item.id}
                                                                key={item.id}
                                                                name="radioNameSort"
                                                                label={translate(item.translate)}
                                                                defaultChecked={sortOrderStates === item.id}
                                                                onChange={setSortOrderStates}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                                        <ButtonThird
                                            onClick={() => {
                                                setSortOrderStates("");
                                                sort(filterData);
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            {translate('home.sort.clear')}
                                        </ButtonThird>
                                        <ButtonPrimary
                                            className={'bg-primary-500 hover:bg-primary-600'}
                                            onClick={() => {
                                                closeModalMoreFilter();
                                                sort(filterData);
                                            }}
                                            sizeClass="px-4 py-2 sm:px-5"
                                        >
                                            {translate('home.sort.apply')}
                                        </ButtonPrimary>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        );
    };

    return (
        <div className="nc-AccountCommonLayout container">
            <BgGlassmorphism/>
            {!countriesLoading && !templatesLoading && currentLocale ?
                <div className="mt-12 sm:mt-12">
                    <div className="nc-SectionGridFeatureItems relative">
                        <div className={'flex flex-col relative mb-12'}>

                            <Select
                                value={defaultCountries}
                                isMulti
                                name={'name'}
                                cacheOptions={1}
                                defaultOptions={true}
                                onChange={change}
                                loadOptions={promiseOptions}
                                components={{
                                    DropdownIndicator: (props) => <DropdownIndicator {...props}
                                    />
                                }}
                                placeholder={translate('home.searchPlaceholder')}
                            />

                        </div>
                        {state.tenant.productManagement && state.tenant.productManagement.enable ?
                            <Nav
                                className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
                                containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
                            >
                                {tabMenu.sort((a: any, b: any) => {
                                    if (a.priority > b.priority) {
                                        return 1
                                    }
                                    return -1;
                                }).map((item: any, index: number) => (
                                    <NavItem
                                        //  className={`px-3.5 py-2 text-sm sm:px-7 sm:py-3 capitalize ${item.className? item.className : ''}`}
                                        key={index}
                                        isActive={tabActive === item.label}
                                        onClick={() => setTabActive(item.label)}
                                        disabled={() => isLabelDisabled(item.label)}
                                        className={item.label + "_" + (tabActive === item.label) + " px-3.5 py-2 text-sm sm:px-7 sm:py-3 "}
                                    >
                                        <div
                                            className={`flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm`}>
                                            {item.label === 'UNKNOWN' ? null :
                                                <span
                                                    className="inline-block"
                                                    //@ts-ignore
                                                    dangerouslySetInnerHTML={{__html: atob(item.icon)}}
                                                ></span>}
                                            <span>{translate(item.title)}</span>
                                        </div>
                                    </NavItem>
                                ))}
                            </Nav> : null}

                        <div className="flex lg:space-x-4 mb-12">
                            {/* FOR DESKTOP */}
                            <div className="hidden lg:flex flex-1 space-x-4">
                                {renderTabIsOnSale()}
                                <div className="!ml-auto">{renderTabsSortOrder()}</div>
                            </div>

                            {/* FOR RESPONSIVE MOBILE */}
                            <div className="flex overflow-x-auto lg:hidden space-x-4">
                                {renderTabMobileFilter()}
                            </div>
                        </div>

                        <div className="grid gap-8 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 mb-12">
                            {filterData.map((item, index) => (
                                <div key={index}>
                                    <SimView
                                        name={item.name}
                                        desc={item.description}
                                        //@ts-ignore
                                        featuredImage={item.featuredImage}
                                        //@ts-ignore
                                        backgroundColor={item.backgroundColorHex}
                                        backgroundImageUrl={item.backgroundImageUrl}
                                        color={item.colorHex}
                                        icon={item.icon}
                                        plan={item}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {modalPlan ?
                        <ModalQuickView
                            show={showModal}
                            onCloseModalQuickView={() => {
                                navigate(`/${location.search}`)
                                setShowModal(false);
                                //@ts-ignore
                                setModalPlan(null);
                                setModalClosed(true)
                            }}
                            plan={modalPlan}
                            allowBuy={true}
                        /> : null}
                </div>
                : null}

        </div>
    );
};

export default Home;
